@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');
@import url('https://unpkg.com/leaflet@1.9.4/dist/leaflet.css');

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #c4c1c139;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #82828282;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #676767a2;
}

input::file-selector-button {
  color: white;
  border: 2px solid rgb(248, 113, 113);
  background-color: rgb(255, 150, 150);
  border-radius: 50px;
  padding: 0.15rem 1rem 0.15rem 1rem;
  transition: 0.15s;
}

input::file-selector-button:hover {
  background-color: rgb(248, 113, 113);
  cursor: pointer;
}

input::file-selector-button:active {
  background-color: rgb(218, 100, 100);
}

.grid-cols-custom {
  grid-template-columns: auto 1fr;
}

.leaflet-control-attribution {
  display: none !important;
}

.spinner {
  border: 4px solid rgb(215, 215, 215);
  border-top: 4px solid #ffffff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.custom-toast-container {
  top: 100px !important;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('./caret-down-solid.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  transition: 0.15s;
}
select:hover{
  cursor: pointer;
}
select:focus {
  outline: none;
}
.z-1000 {
  z-index: 1000;
}

.z-500 {
  z-index: 500;
}

.custom-select-register{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: none;
}

.custom-select-register:hover {
  cursor:auto;
}